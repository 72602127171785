var app = {
    init: function() {
        //slider
        var slides = document.querySelectorAll('#slides .slide');
        var currentSlide = 0;
        var timer= 3000;
        var slideInterval = setInterval(nextSlide,timer);
        var next = document.getElementById('next');
        var previous = document.getElementById('previous');

        next.onclick = function() {
        clearInterval(slideInterval);
        slideInterval = setInterval(nextSlide,timer);
        nextSlide();
        };
        previous.onclick = function() {
        clearInterval(slideInterval);
        slideInterval = setInterval(nextSlide,timer);
        previousSlide();
        };

        function nextSlide(){
            goToSlide(currentSlide+1);
        }

        function previousSlide(){
            goToSlide(currentSlide-1);
        }

        function goToSlide(n){
            slides[currentSlide].className = 'slide';
            currentSlide = (n+slides.length)%slides.length;
            slides[currentSlide].className = 'slide showing';
        }
        
        var ele = document.getElementById("slides");
        var touchstartX = 0;
        var touchendX = 0;

        ele.addEventListener('touchstart', function(event) {
            touchstartX = event.changedTouches[0].screenX;
        }, false);

        ele.addEventListener('touchend', function(event) {
            touchendX = event.changedTouches[0].screenX;
            handleSwipe();
        }, false); 

        function handleSwipe() {
            if (touchendX > touchstartX) {
                goToSlide(currentSlide-1);
            } else if ( touchendX < touchstartX) {
                goToSlide(currentSlide+1);
            }
        }
    }
}

app.init();